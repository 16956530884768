require('./bootstrap');

//creamos nueva instancia de la aplicacion vue con la funcion createApp
import { createApp } from 'vue';

// Importación de componentes padre.
import Tamonante from './components/Tamonante.vue'; 

// Comenzar a montar los componentes padres.

const app = createApp(Tamonante);

// Agregar sub-componentes al componente padre (Tamonante).

app.component('navbar', require('./components/main/Navbar.vue').default);
app.component('Contacto', require('./components/main/Contacto.vue').default);

//Montar componentes.
app.mount('#app');



