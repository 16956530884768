<template>
  <nav class="navbar navbar-dark bg-dark navbar-expand-md">
    <a class="navbar-brand"> TAMONANTE EDUCA</a>

    <button
      class="navbar-toggler"
      data-bs-toggle="collapse"
      data-bs-target="#navbar"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse" id="navbar">
      <ul class="navbar-nav">
        <li class="nav-link"><a class="nav-link" href="/">Contacto</a></li>
        <li class="nav-link">
          <a class="nav-link" href="/home">Area Personal</a>
        </li>
      </ul>
    </div>
  </nav>
</template>