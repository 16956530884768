<template>
  <form method="POST" v-on:submit.prevent="enviarInfoContacto()">
    <div class="mt-5 mx-md-5 mx-lg-5 mx-xl-5 mx-xxl-5">
      <div class="row">
        <div
          class="
            col-md-5
            mx-md-5
            col-lg-5
            mx-lg-5
            col-xl-5
            mx-xl-5
            col-xxl-5
            mx-xxl-5
          "
        >
          <div class="shadow-lg p-3 mb-5 bg-white rounded">
            <div
              v-if="success.length > 0"
              class="alert alert-success"
              role="alert"
            >
              {{ success }}
            </div>

            <div
              v-if="error.length > 0"
              class="alert alert-danger"
              role="alert"
            >
              {{ error }}

              <ul class="m-0" v-if="Object.keys(error_list).length > 0">
                <li v-for="msg in error_list" v-bind:key="msg">
                  {{ msg.at(0) }}
                </li>
              </ul>
            </div>

            <div class="card-header mb-4">Contacta con nosotros</div>

            <div class="mb-4">
              <label for="nombre" class="form-label">Nombre</label>
              <input
                type="text"
                name="nombre"
                class="form-control input-sm form-control"
                id="nombre"
                v-model="persona.nombre"
                v-on:blur="validarDatos()"
                :class="valida_formato.nombre"
                maxlength="50"
              />
              <div class="valid-feedback"><strong>Nombre</strong> válido.</div>
              <div class="invalid-feedback">
                Debe proporcionar un <strong>Nombre</strong> válido que no
                supere los 50 caracteres.
              </div>
            </div>

            <div class="mb-4">
              <label for="apellidos" class="form-label">Apellidos</label>
              <input
                type="text"
                name="apellidos"
                class="form-control input-sm"
                id="apellidos"
                v-model="persona.apellidos"
                v-on:blur="validarDatos()"
                :class="valida_formato.apellidos"
                maxlength="100"
              />
              <div class="valid-feedback">
                <strong>Apellidos</strong> válidos.
              </div>
              <div class="invalid-feedback">
                Debe proporcionar unos <strong>Apellidos</strong> válidos que no
                superen los 100 caracteres.
              </div>
            </div>

            <div class="mb-4">
              <label for="correo" class="form-label">Correo electrónico</label>
              <input
                type="email"
                name="correo"
                class="form-control input-sm"
                id="correo"
                v-model="persona.correo"
                v-on:blur="validarDatos()"
                :class="valida_formato.correo"
                placeholder="name@example.com"
                maxlength="150"
              />
              <div class="valid-feedback">
                <strong>Correo electrónico</strong> válido.
              </div>
              <div class="invalid-feedback">
                Debe proporcionar un <strong>Correo electrónico</strong> válido
                que no supere los 150 caracteres.
              </div>
            </div>

            <div class="mb-4">
              <label for="telefono" class="form-label">Teléfono</label>
              <input
                type="text"
                name="telefono"
                class="form-control input-sm"
                id="telefono"
                v-model="persona.telefono"
                v-on:blur="validarDatos()"
                v-on:keydown="esNumero($event)"
                :class="valida_formato.telefono"
                placeholder="+34 679001122 o 0034 679001122"
                maxlength="50"
              />
              <div class="valid-feedback">
                <strong>Teléfono</strong> válido.
              </div>
              <div class="invalid-feedback">
                <p>
                  Debe proporcionar un <strong>Teléfono</strong> válido que no
                  supere los 50 caracteres.
                </p>
                <p>Ejemplos: +34 679001122 o 0034 679001122</p>
              </div>
            </div>

            <div class="mb-4">
              <label for="mensaje" class="form-label">Mensaje</label>
              <textarea
                class="form-control"
                name="mensaje"
                id="mensaje input-sm"
                v-model="persona.mensaje"
                v-on:blur="validarDatos()"
                :class="valida_formato.mensaje"
                rows="3"
                maxlength="255"
              ></textarea>
              <div
                class="text-success"
                v-if="valida_formato.mensaje == 'border-success'"
              >
                <strong>Mensaje</strong> cumplimentado.
              </div>
              <div
                class="text-danger"
                v-if="valida_formato.mensaje == 'border-danger'"
              >
                Debe cumplimentar el <strong>Mensaje</strong> con un máximo de
                255 caracteres.
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <input type="submit" class="btn btn-primary" value="Enviar" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  data: function () {
    return {
      success: "", // Mostrar un mensaje en verde para confirmar que el controlador ha tomado los datos.
      error: "", // Para mostrar el mensaje en rojo cuando se produce error en el controlador.
      error_list: {}, // Lista de los errores que devuelve el controlador.
      persona: {
        // Contiene la información de los input.
        nombre: null,
        apellidos: null,
        correo: null,
        telefono: null,
        mensaje: null,
      },
      valida_formato: {
        // Para agregar la clase de is-invalid o is-valid a los input. (Darle colores)
        nombre: "",
        apellidos: "",
        correo: "",
        telefono: "",
        mensaje: "",
      },
      datos_validos: {
        // Verificación final para ver si los datos del formulario son aptos o no para ser enviados al servidor.
        nombre: false,
        apellidos: false,
        correo: false,
        telefono: false,
        mensaje: false,
      },
    };
  },
  methods: {
    // Enviar información al controlador una vez que la información es válida.
    enviarInfoContacto() {
      // Obtener los input y el text area para aplicarle formato.
      Array.from(document.querySelectorAll("input, textarea")).forEach((el) =>
        el.blur()
      ); // Para eliminar el focus del elemento activo y validar al presionar el botón de Enviar.
      if (
        this.datos_validos.nombre &&
        this.datos_validos.apellidos &&
        this.datos_validos.correo &&
        this.datos_validos.telefono &&
        this.datos_validos.mensaje
      ) {
        axios
          .post("/guardar", this.persona)
          .then((response) => {
            this.success = response.data.message; // Mostrar mensaje success y ocultar el error.
            this.error = "";
            this.limpiarFormulario();
          })
          .catch((error) => {
            this.success = ""; // Ocultar mensaje de satisfactorio y mostrar el de error.

            // Analizar código de los mensajes de error.

            switch (error.response.status) {
              case 500: // Problema del servidor.
                if (error.response.data.errors) {
                  // If para evitar el error undefinied.
                  this.error = error.response.data.errors;
                } else {
                  this.error = "Error al procesar la solicitud en el servidor.";
                }

                break;

              case 422: // Datos erróneos (que no han pasado la validación del servidor).
                this.error =
                  "No se ha podido procesar la información debido a las siguientes causas:";
                this.error_list = error.response.data.errors;
                // Resaltar en rojo los campos que no son válidos con la clase is-invalid.
                Object.keys(this.error_list).forEach((key) => {
                  document
                    .querySelector("[name='" + key + "']")
                    .classList.add("is-invalid");
                  document
                    .querySelector("[name='" + key + "']")
                    .classList.remove("is-valid");
                });
                break;
            }
          });
      } else {
        this.mostrarErrores();
      }
    },
    limpiarFormulario() {
      this.persona.nombre = null;
      this.valida_formato.nombre = null;
      this.datos_validos.nombre = false;
      this.persona.apellidos = null;
      this.valida_formato.apellidos = null;
      this.datos_validos.apellidos = false;
      this.persona.correo = null;
      this.valida_formato.correo = null;
      this.datos_validos.correo = false;
      this.persona.telefono = null;
      this.valida_formato.telefono = null;
      this.datos_validos.telefono = false;
      this.persona.mensaje = null;
      this.valida_formato.mensaje = null;
      this.datos_validos.mensaje = false;
    },

    /* mostrarErrores() -> Informa al usuario de los campos erróneos cuando trata de enviar los datos al servidor sin 
      haber rellenado previamente todos los campos del formulario. */

    mostrarErrores() {
      this.success = "";

      // Nombre.
      if (this.persona.nombre) {
        // Comprobar si es null.
        if (this.persona.nombre.length > 0 && this.persona.nombre <= 50) {
          // Comprobar longitud del campo.
          this.valida_formato.nombre = "is-valid";
        } else {
          this.valida_formato.nombre = "is-invalid";
        }
      } else {
        this.valida_formato.nombre = "is-invalid";
      }

      // Apellidos.

      if (this.persona.apellidos) {
        if (
          this.persona.apellidos.length > 0 &&
          this.persona.apellidos.length <= 100
        ) {
          this.valida_formato.apellidos = "is-valid";
        } else {
          this.valida_formato.apellidos = "is-invalid";
        }
      } else {
        this.valida_formato.apellidos = "is-invalid";
      }

      // Correo

      if (this.persona.correo) {
        if (
          this.persona.correo.length > 0 &&
          this.persona.correo.length <= 150
        ) {
          if (
            /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/.test(
              this.persona.correo
            )
          ) {
            this.valida_formato.correo = "is-valid";
          } else {
            this.valida_formato.correo = "is-invalid";
          }
        } else {
          this.valida_formato.correo = "is-invalid";
        }
      } else {
        this.valida_formato.correo = "is-invalid";
      }

      // Telefono

      if (this.persona.telefono) {
        if (
          this.persona.telefono.length > 0 &&
          this.persona.telefono.length <= 50
        ) {
          if (
            /^\+{1,1}[1-9]+\s?\d+$/.test(this.persona.telefono) ||
            /^\d+\s?\d+$/.test(this.persona.telefono)
          ) {
            this.valida_formato.telefono = "is-valid";
          } else {
            this.valida_formato.telefono = "is-invalid";
          }
        } else {
          this.valida_formato.telefono = "is-invalid";
        }
      } else {
        this.valida_formato.telefono = "is-invalid";
      }

      // Mensaje

      if (this.persona.mensaje) {
        if (
          this.persona.mensaje.length > 0 &&
          this.persona.mensaje.length <= 255
        ) {
          this.valida_formato.mensaje = "border-success";
        } else {
          this.valida_formato.mensaje = "border-danger";
        }
      } else {
        this.valida_formato.mensaje = "border-danger";
      }
    },

    /* 
      validarDatos() -> Valida cada input una vez que el usuario ha escrito en él.
    */

    validarDatos() {
      // Nombre.

      if (this.persona.nombre !== null) {
        // Comprobar si está o no nulo
        if (
          this.persona.nombre.length > 0 &&
          this.persona.nombre.length <= 50
        ) {
          this.valida_formato.nombre = "is-valid";
          this.datos_validos.nombre = true;
        } else {
          this.valida_formato.nombre = "is-invalid";
          this.datos_validos.nombre = false;
        }
      }

      // Apellidos.

      if (this.persona.apellidos !== null) {
        if (
          this.persona.apellidos.length > 0 &&
          this.persona.apellidos.length <= 100
        ) {
          this.valida_formato.apellidos = "is-valid";
          this.datos_validos.apellidos = true;
        } else {
          this.valida_formato.apellidos = "is-invalid";
          this.datos_validos.apellidos = false;
        }
      }

      // Correo electrónico.

      if (this.persona.correo !== null) {
        // Comprobar si está o no nulo
        if (
          this.persona.correo.length > 0 &&
          this.persona.correo.length <= 150 &&
          /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/.test(
            this.persona.correo
          ) // Comprobar si la dirección de correo cumple con el formato requerido.
        ) {
          this.valida_formato.correo = "is-valid";
          this.datos_validos.correo = true;
        } else {
          this.valida_formato.correo = "is-invalid";
          this.datos_validos.correo = false;
        }
      }

      // Teléfono

      if (this.persona.telefono !== null) {
        // Comprobar si está o no nulo
        /*
            Comprobar si el número de teléfono cumple los siguientes formatos admitidos:
              Formato 1 -> +34 679001122
              Formato 2 -> 0034 679001122
          */
        if (
          (this.persona.telefono.length > 0 &&
            this.persona.telefono.length <= 50 &&
            /^\+{1,1}[1-9]+\s?\d+$/.test(this.persona.telefono)) ||
          /^\d+\s?\d+$/.test(this.persona.telefono)
        ) {
          this.valida_formato.telefono = "is-valid";
          this.datos_validos.telefono = true;
        } else {
          this.valida_formato.telefono = "is-invalid";
          this.datos_validos.telefono = false;
        }
      }

      // Mensaje

      if (this.persona.mensaje !== null) {
        // Comprobar si está o no nulo
        if (
          this.persona.mensaje.length > 0 &&
          this.persona.mensaje.length <= 255
        ) {
          this.valida_formato.mensaje = "border-success";
          this.datos_validos.mensaje = true;
        } else {
          this.valida_formato.mensaje = "border-danger";
          this.datos_validos.mensaje = false;
        }
      }
    },
    /*
      Comprueba si la tecla pulsada está admitida, en caso contrario se invalida la pulsación.
    */
    esNumero(event) {
      let key = event.key.charCodeAt(0);

      let charAllowed = [
        32, // Espacio en blanco.
        43, // +
        65, // Flecha superior, inferior, izquierda, derecha.
        66, // Eliminar.
        84, // Tabulador.
        48, // Números del 0-9
        49,
        50,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
      ];

      if (charAllowed.indexOf(key) == -1) event.preventDefault(); // Anulamos el evento, por lo tanto, si no es un carácter admitido no se escribirá.
    },
  },
};
</script>
